@import "/home/runner/work/caritas-rework-onlineBeratung-frontend/caritas-rework-onlineBeratung-frontend/src/resources/styles/settings.scss";  @import 'vendor/print.css';
@import 'vendor/sanitize.css';
@import 'layout';
@import 'banner';
@import 'display';
@import 'types';
@import 'flex';
@import 'float';
@import 'text';
@import 'colors';
@import 'padding';
@import 'margin';
@import 'border';
@import 'icon';

.w--100 {
	width: 100%;
}

@if mixin-exists(setup-fonts) {
	@include setup-fonts();
}
