@import "/home/runner/work/caritas-rework-onlineBeratung-frontend/caritas-rework-onlineBeratung-frontend/src/resources/styles/settings.scss";  .notice {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	border-radius: 4px;
	padding: $notice-padding;
	background-color: $notice-background;
	border: $notice-border;

	&__header {
		display: flex;
		width: 100%;
		margin-bottom: 6px;
	}

	&__title {
		flex: 1;
		min-height: 24px;
		line-height: 24px;
		font-size: $font-size-h5;
		font-weight: $font-weight-bold;
	}

	&__icon {
		flex: 0 24px;
		height: 24px;
		text-align: center;
		border-radius: 50%;
		margin-right: 12px;

		svg {
			width: 14px;
			height: 14px;
		}
	}

	&__text {
		font-size: $font-size-tertiary;
	}

	&--info,
	&--warning,
	&--error,
	&--success {
		text-align: left;
	}

	&--info &__icon {
		svg {
			width: 24px;
			height: 24px;
		}
	}

	&--success &__icon {
		background: $form-success;
	}

	&--error &__icon {
		background: $form-error;
	}

	&--warning &__icon {
		background: $form-medium;
	}
}
