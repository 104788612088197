html,
body {
	min-height: 100%;
	height: 100%;
	min-width: $body-min-width;
}

html,
body,
.app__container,
.stageLayout,
.app {
	height: 100%;
}

html {
	box-sizing: border-box;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

//only for tenant theming
:root {
	--skin-color-primary-foreground-dark: #{$body-font-color};
	--skin-color-primary-foreground-light: #{$white};
}

body {
	font-family: $body-font-family;
	background-color: var(--skin-color-body-background, $body-bg);
	font-size: $body-font-size;
	line-height: $body-line-height;
	color: $body-font-color;

	min-width: $body-min-width;

	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

hr {
	width: 100%;
	border-bottom: 0;
	border-color: rgba(0, 0, 0, 0.2);
	margin: 0 0 $grid-base-two;
}

*[role='button'] {
	cursor: pointer;

	&:hover {
		color: var(--skin-color-primary-contrast-safe, $hover-primary);
	}
}

.layout {
	&--default {
		margin: 0;
	}
}

.content {
	height: 100%;

	&:before,
	&:after {
		content: ' ';
		display: table;
	}

	&:after {
		clear: both;
	}
}

.content > article {
	height: 100%;
}
